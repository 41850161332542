/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const StepThree = () => {
  const history = useHistory()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(true);
  const [question, setQuestion] = useState('');
  const [hasModal, setHasModal] = useState(false);

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (question) {
      setIsCorrect(true)
    }
  }, [question])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <p
            style={{
              color: '#2abf00',
              fontSize: '32px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Khảo sát'
              :
              'Survey'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-20px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'SỨC KHỎE'
              :
              'MENTAL'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-25px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'TINH THẦN'
              :
              'HEALTH'
            }
          </p>

          <p
            style={{
              color: '#000345',
              fontSize: '20px',
              textAlign: 'left',
              fontFamily: 'UTM-APTIMA',
              lineHeight: 1.6,
              marginBottom: '30px'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Trong phần phân tích này, hệ thống camera Anbi sẽ đưa ra nhận định về 2 nhóm tính cách lớn là tính cách chính và phụ của bạn, là hệ thống cảm xúc đã được tích lũy qua nhiều năm. Tuy nhiên để bản báo cáo trở nên toàn diện nhất, chúng tôi mời bạn thực hiện 2 câu hỏi khảo sát sức khỏe tinh thần là trạng thái hạnh phúc hiện tại của bạn, có thể mới được hình thành trong thời gian ngắn, nằm ngoài khả năng hoạt động của Anbi.'
                :
                'In this analysis, the Anbi camera system will make comments about two large personality groups: your main and secondary personalities, which are emotional systems that have been accumulated over many years. However, to make the report the most comprehensive, we invite you to complete two mental health survey questions: your current state of happiness, which may have just been formed in a short time, outside Anbi\'s operational capabilities.'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Trong phần phân tích này, hệ thống camera Anbi sẽ đưa ra nhận định về 2 nhóm tính cách lớn là tính cách chính và phụ của bạn, là hệ thống cảm xúc đã được tích lũy qua nhiều năm. Tuy nhiên để bản báo cáo trở nên toàn diện nhất, chúng tôi mời bạn thực hiện 2 câu hỏi khảo sát sức khỏe tinh thần là trạng thái hạnh phúc hiện tại của bạn, có thể mới được hình thành trong thời gian ngắn, nằm ngoài khả năng hoạt động của Anbi.'
                :
                'In this analysis, the Anbi camera system will make comments about two large personality groups: your main and secondary personalities, which are emotional systems that have been accumulated over many years. However, to make the report the most comprehensive, we invite you to complete two mental health survey questions: your current state of happiness, which may have just been formed in a short time, outside Anbi\'s operational capabilities.'
              }
            </>}
          </p>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(89, 89, 89, 0.5)',
            }}
          >
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              width: '380px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              fontFamily: 'UTM-APTIMABOLD',
              paddingBottom: '20px',
              height: '100%',
              overflow: 'scroll'
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/canh-bao-anbi.jpg')} style={{ width: '340px', height: 'auto'}} alt='' />

            <div
              onClick={() => history.push('/step-five')}
              style={{
                width: '90%',
                border: '2px solid #ddd',
                borderRadius: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: '#30a3fc',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                marginBottom: '20px',
                fontFamily: 'UTM-APTIMA',
                margin: 'auto',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC KHẢO SÁT'
                :
                'CONTINUE SURVEY'
              }
            </div>

            <div style={{ height: '16px' }}></div>

            <div
              onClick={() => history.push('/note-scan')}
              style={{
                width: '90%',
                border: '2px solid #ddd',
                borderRadius: '10px',
                fontSize: '20px',
                fontWeight: 'bold',
                backgroundColor: '#d0d0d0',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                marginBottom: '20px',
                fontFamily: 'UTM-APTIMA',
                margin: 'auto',
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XÁC NHẬN BỎ QUA'
                :
                'CONFIRM SKIP'
              }
            </div>

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              marginBottom: '25px'
            }}
            onClick={() => {
              setIsCorrect(false)
              history.push('/step-five')
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC'
                :
                'CONTINUE'
              }
            </span>
          </button>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#d0d0d0' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setHasModal(true)
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'BỎ QUA'
                :
                'SKIP'
              }
            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {StepThree}
