/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const StepTwo2 = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(false);
  const [questionOne, setQuestionOne] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (questionOne) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [questionOne])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >


        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >

          <p
            style={{
              color: '#2abf00',
              fontSize: '32px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Khảo sát'
              :
              'Survey of'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-20px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'NHU CẦU'
              :
              'Learning'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-25px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'HỌC TẬP'
              :
              'Needs'
            }
          </p>

          <p
            style={{
              color: '#003345',
              fontSize: '24px',
              textAlign: 'justify',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Trong thời gian tới, chúng tôi sẽ dành tặng bạn 1 trong 3 khóa học để giúp bạn khắc phục những điểm yếu bạn đang gặp phải. Bạn hãy chọn khóa học bạn cần nhất.'
              :
              'In the near future, we will give you 1 of 3 courses to help you overcome the weaknesses you are facing. Please choose the course you need the most.'
            }
          </p>
          <div
            style={{
              height: '20px',
            }}
          >
          </div>


          <div
            onClick={() => {setQuestionOne("ONE_A")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionOne === "ONE_A" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionOne === "ONE_A" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Khóa học 1: Kiểm soát cơn nóng giận, cân bằng cuộc sống'
              :
              'Course 1: Control anger, balance life'
            }
          </div>

          <div
            onClick={() => {setQuestionOne("ONE_B")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionOne === "ONE_B" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionOne === "ONE_B" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Khóa học 2: Kỹ năng giao tiếp và xây dựng mối quan hệ'
              :
              'Course 2: Communication skills and relationship building'
            }
          </div>

          <div
            onClick={() => {setQuestionOne("ONE_C")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionOne === "ONE_C" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionOne === "ONE_C" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Khóa học 3: Kỹ năng ra quyết định và giải quyết xung đột'
              :
              'Course 3: Decision making and conflict resolution skills'
            }

          </div>




          <div
            style={{
              height: '60px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              dispatch({
                type: 'SET_QUESTION_ONE',
                payload: {
                  questionOne: questionOne
                }
              })
              history.push('/step-three')
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMA'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XÁC NHẬN'
                :
                'CONFIRM'
              }
            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {StepTwo2}
