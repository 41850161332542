export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const SET_STEP_ONE = "SET_STEP_ONE"
export const SET_STEP_TWO = "SET_STEP_TWO"
export const SET_QUESTION_ONE = "SET_QUESTION_ONE"
export const SET_QUESTION_TWO = "SET_QUESTION_TWO"
export const SET_QUESTION_THREE = "SET_QUESTION_THREE"
export const SET_QUESTION_FOUR = "SET_QUESTION_FOUR"
export const SET_QUESTION_FIVE = "SET_QUESTION_FIVE"
export const SET_ADDRESS = "SET_ADDRESS"
export const SET_LANGUAGE = "SET_LANGUAGE"
export const SET_IS_PARENT_HELP = "SET_IS_PARENT_HELP"
export const SET_CHARACTER = "SET_CHARACTER"
export const SET_SCAN_CODE = "SET_SCAN_CODE"

const initialState = {
  name: '',
  phone: '',
  password: '',
  agency_alias: '',

  birth_day: '',
  birth_month: '',
  birth_year: '',
  gender: '',
  province_id: '',
  district_id: '',
  is_child: 0,
  is_parent_help: '',

  question_one: '',
  question_two: '',
  question_three: '',
  question_four: '',
  question_five: '',

  address: '',
  language: 'VI',

  family_phone: '',
  family_role: '',

  character_id: 1,
  scan_code: '',

}

export default function userReducer(state = initialState, payload) {
  switch (payload.type) {
    case SET_STEP_ONE:
      return {
        ...state,
        name: payload.payload.name,
        birth_year: payload.payload.birthYear,
        phone: payload.payload.phone,
        password: payload.payload.password,
        agency_alias: payload.payload.agencyAlias,
        family_phone: payload.payload.familyPhone,
        family_role: payload.payload.familyRole,
        gender: payload.payload.gender,
      }
    case SET_STEP_TWO:
      return {
        ...state,
        birth_day: payload.payload.birthDay,
        birth_month: payload.payload.birthMonth,
        birth_year: payload.payload.birthYear,
        gender: payload.payload.gender,
        is_child: payload.payload.isChild,
        province_id: payload.payload.provinceId,
        district_id: payload.payload.districtId,
      }
    case SET_QUESTION_ONE:
      return {
        ...state,
        question_one: payload.payload.questionOne,
      }
    case SET_QUESTION_TWO:
      return {
        ...state,
        question_two: payload.payload.questionTwo,
      }
    case SET_QUESTION_THREE:
      return {
        ...state,
        question_three: payload.payload.questionThree,
      }
    case SET_QUESTION_FOUR:
      return {
        ...state,
        question_four: payload.payload.questionFour,
      }
    case SET_QUESTION_FIVE:
      return {
        ...state,
        question_five: payload.payload.questionFive,
      }
    case SET_ADDRESS:
      return {
        ...state,
        address: payload.payload.address,
      }
    case SET_LANGUAGE:
      return {
        ...state,
        language: payload.payload.language,
      }
    case SET_IS_PARENT_HELP:
      return {
        ...state,
        is_parent_help: payload.payload.isParentHelp,
      }
    case SET_CHARACTER:
      return {
        ...state,
        character_id: payload.payload.characterId,
      }
    case SET_SCAN_CODE:
      return {
        ...state,
        scan_code: payload.payload.scanCode,
      }
    case LOGOUT:
      return {
        ...initialState
      }
    default:
      return state
  }
}
