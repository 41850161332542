import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const NoteScan = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [language, setLanguage] = useState('male');
  const [isChild, setIsChild] = useState(0);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [currentDistricts, setCurrentDistricts] = useState([])
  const [isCorrect, setIsCorrect] = useState(false);
  const [hasModal, setHasModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  useEffect(() => {
    if (userInfo.name && userInfo.phone && userInfo.agency_alias) {
      axios.post(`${BACKEND_URL}/customer/free-register`, {
        name: userInfo.name,
        phone: userInfo.phone,
        password: userInfo.password,
        agency_alias: userInfo.agency_alias,
        birth_day: userInfo.birth_day,
        birth_month: userInfo.birth_month,
        birth_year: userInfo.birth_year,
        gender: userInfo.gender,
        province_id: userInfo.province_id,
        district_id: userInfo.district_id,
        is_child: userInfo.is_child,
        question_one: userInfo.question_one,
        question_two: userInfo.question_two,
        question_three: userInfo.question_three,
        question_four: userInfo.question_four,
        question_five: userInfo.question_five,
        family_phone: userInfo.family_phone,
        family_role: userInfo.family_role,
      }).then(res => res.data)
        .then(resData => {
          if (resData.error_code === 1) {
            console.log({resData})
            dispatch({
              type: 'SET_SCAN_CODE',
              payload: {
                scanCode: resData.data.new_user.scan_code
              }
            })
            setErrorMsg('')
          } else {
            setErrorMsg('Đăng ký không thành công, vui lòng thử lại hoặc liên hệ với quản trị viên!')
          }
        })
    }
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div>
          <img src={toAbsoluteUrl('/media/logos/note-scan.jpg')} style={{ width: WIDTH }} alt='intro' />
        </div>
        <div
          style={{
            width: '100%',
            padding: '0px 30px 30px 30px'
          }}
        >
          {errorMsg &&
          <p
            className='fs-1 text-danger'
            style={{
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >{errorMsg}</p>
          }

          <button
            type="button"
            style={{
              display: !errorMsg ? 'block' : 'none',
              width: '100%',
              padding: '15px',
              backgroundColor: '#30a3fc',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              fontFamily: 'UTM-APTIMA',
              marginTop: '25px'
            }}
            onClick={() => {
              history.push('/step-eight')
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0
              }}
            >
              TIẾP TỤC
            </span>
          </button>

          <button
            type="button"
            style={{
              display: errorMsg ? 'block' : 'none',
              width: '100%',
              padding: '15px',
              backgroundColor: '#30a3fc',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              fontFamily: 'UTM-APTIMA',
              marginTop: '25px'
            }}
            onClick={() => {
              history.push('/step-one')
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0
              }}
            >
              THỬ LẠI
            </span>
          </button>
        </div>


      </div>

    </div>
  )
}

export {NoteScan}
