/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";

const StepFive = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [isCorrect, setIsCorrect] = useState(false);
  const [questionTwo, setQuestionTwo] = useState('');

  const userInfo = useSelector(state => state.userInfo)
  const isParentHelp = userInfo.is_parent_help === 1
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (questionTwo) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [questionTwo])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <p
            style={{
              color: '#2abf00',
              fontSize: '32px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Khảo sát'
              :
              'Survey'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-20px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'SỨC KHỎE'
              :
              'MENTAL'
            }
          </p>
          <p
            style={{
              color: '#006dff',
              fontSize: '42px',
              fontFamily: 'UTM-APTIMABOLD',
              marginTop: '-25px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'TINH THẦN'
              :
              'HEALTH'
            }
          </p>

          <p
            style={{
              color: '#003345',
              fontSize: '24px',
              textAlign: 'justify',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bạn đánh giá mức độ hạnh phúc của mình ở mức nào?'
                :
                'How do you rate your level of happiness?'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Bạn đánh giá mức độ hạnh phúc của mình ở mức nào?'
                :
                'How do you rate your level of happiness?'
              }
            </>}
          </p>
          <div
            style={{
              height: '20px',
            }}
          >
          </div>


          <div
            onClick={() => {setQuestionTwo("TWO_A")}}
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionTwo === "TWO_A" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              backgroundColor: questionTwo === "TWO_A" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 1: Hạnh phúc và biết ơn sâu sắc'
                :
                'Level 1: Happiness and deep gratitude'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 1: Hạnh phúc và biết ơn sâu sắc'
                :
                'Level 1: Happiness and deep gratitude'
              }
            </>}

          </div>


          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionTwo === "TWO_B" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionTwo === "TWO_B" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
            onClick={() => {setQuestionTwo("TWO_B")}}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 2: Hài lòng và thoải mái'
                :
                'Level 2: Satisfied and comfortable'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 2: Hài lòng và thoải mái'
                :
                'Level 2: Satisfied and comfortable'
              }
            </>}
          </div>

          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionTwo === "TWO_C" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionTwo === "TWO_C" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
            onClick={() => {setQuestionTwo("TWO_C")}}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 3: Bình thường và ổn định'
                :
                'Level 3: Normal and stable'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 3: Bình thường và ổn định'
                :
                'Level 3: Normal and stable'
              }
            </>}
          </div>

          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionTwo === "TWO_D" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionTwo === "TWO_D" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
            onClick={() => {setQuestionTwo("TWO_D")}}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 4: Khó hài lòng và thường lo lắng'
                :
                'Level 4: Difficult to please and often anxious'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 4: Khó hài lòng và thường lo lắng'
                :
                'Level 4: Difficult to please and often anxious'
              }
            </>}
          </div>

          <div
            style={{
              width: '100%',
              border: '2px solid #ddd',
              borderRadius: '10px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: questionTwo === "TWO_E" ? 'white' : '#bebebe',
              textAlign: 'left',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
              marginTop: '5%',
              backgroundColor: questionTwo === "TWO_E" ? '#787878' : '#f5f5f5',
              fontFamily: 'UTM-APTIMABOLD'
            }}
            onClick={() => {setQuestionTwo("TWO_E")}}
          >
            {!isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 5: Cảm thấy bất hạnh và thất vọng'
                :
                'Level 5: Feeling unhappy and disappointed'
              }
            </>}
            {isParentHelp && <>
              {LANGUAGE === 'VI'
                ?
                'Mức 5: Cảm thấy bất hạnh và thất vọng'
                :
                'Level 5: Feeling unhappy and disappointed'
              }
            </>}
          </div>

          <div
            style={{
              height: '30px',
            }}
          >
          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              dispatch({
                type: 'SET_QUESTION_TWO',
                payload: {
                  questionTwo: questionTwo
                }
              })
              history.push('/note-scan')
            }}
          >
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMA'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'XÁC NHẬN'
                :
                'CONFIRM'
              }
            </span>
          </button>

        </div>


      </div>

    </div>
  )
}

export {StepFive}
