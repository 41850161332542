/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {toast} from "react-toastify";
import {SET_STEP_ONE} from "../../setup/redux/userReducer";

const StepOne = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  const [name, setName] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [phone, setPhone] = useState('');
  const [familyPhone, setFamilyPhone] = useState('');
  const [familyRole, setFamilyRole] = useState('');
  const [familyRoles, setFamilyRoles] = useState([]);
  const [agencyAlias, setAgencyAlias] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [gender, setGender] = useState('male');

  const addressArr = [
    {
      name: 'BACNINH',
      alias: 'Bắc Ninh - thành phố Bắc Ninh',
      phone: '8888888801'
    },
    {
      name: 'TUSON',
      alias: 'Bắc Ninh - thành phố Từ Sơn',
      phone: '8888888802'
    },
    {
      name: 'QUEVO',
      alias: 'Bắc Ninh - thị xã Quế Võ',
      phone: '8888888803'
    },
    {
      name: 'THUANTHANH',
      alias: 'Bắc Ninh - thị xã Thuận Thành',
      phone: '8888888804'
    },
    {
      name: 'LUONGTAI',
      alias: 'Bắc Ninh - huyện Lương Tài',
      phone: '8888888805'
    },
    {
      name: 'YENPHONG',
      alias: 'Bắc Ninh - huyện Yên Phong',
      phone: '8888888806'
    },
    {
      name: 'GIABINH',
      alias: 'Bắc Ninh - huyện Gia Bình',
      phone: '8888888807'
    },
    {
      name: 'TIENDU',
      alias: 'Bắc Ninh - huyện Tiên Du',
      phone: '8888888808'
    },
    {
      name: 'VINHYEN',
      alias: 'Vĩnh Phúc - thành phố Vĩnh Yên',
      phone: '8888888811'
    },
    {
      name: 'PHUCYEN',
      alias: 'Vĩnh Phúc - thành phố Phúc Yên',
      phone: '8888888812'
    },
    {
      name: 'YENLAC',
      alias: 'Vĩnh Phúc - thị trấn Yên Lạc',
      phone: '8888888813'
    },
    {
      name: 'VINHTUONG',
      alias: 'Vĩnh Phúc - huyện Vĩnh Tường',
      phone: '8888888814'
    },
    {
      name: 'SONGLO',
      alias: 'Vĩnh Phúc - huyện Sông Lô',
      phone: '8888888815'
    },
    {
      name: 'TAMDAO',
      alias: 'Vĩnh Phúc - huyện Tam Đảo',
      phone: '8888888816'
    },
    {
      name: 'TAMDUONG',
      alias: 'Vĩnh Phúc - huyện Tam Dương',
      phone: '8888888817'
    },
    {
      name: 'LAPTHACH',
      alias: 'Vĩnh Phúc - huyện Lập Thạch',
      phone: '8888888818'
    },
    {
      name: 'BINHXUYEN',
      alias: 'Vĩnh Phúc - huyện Bình Xuyên',
      phone: '8888888819'
    },
    {
      name: 'PHULY',
      alias: 'Hà Nam - thành phố Phủ Lý',
      phone: '8888888821'
    },
    {
      name: 'DUYTIEN',
      alias: 'Hà Nam - thị xã Duy Tiên',
      phone: '8888888822'
    },
    {
      name: 'THANHLIEM',
      alias: 'Hà Nam - huyện Thanh Liêm',
      phone: '8888888823'
    },
    {
      name: 'BINHLUC',
      alias: 'Hà Nam - huyện Bình Lục',
      phone: '8888888824'
    },
    {
      name: 'KIMBANG',
      alias: 'Hà Nam - huyện Kim Bảng',
      phone: '8888888825'
    },
    {
      name: 'LYNHAN',
      alias: 'Hà Nam - huyện Lý Nhân',
      phone: '8888888826'
    },
    {
      name: 'TINHKHAC',
      alias: 'Tỉnh khác',
      phone: '8888888831'
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (name && phone && agencyAlias) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [name, phone, agencyAlias, familyPhone, familyRole])

  const checkFamilyPhone = () => {
    if (familyPhone) {
      axios.post(`${BACKEND_URL}/family-role`, {
        family_phone: familyPhone
      }).then(res => res.data)
        .then(resData => {
          setFamilyRoles(resData.data.availableRoles)
          if (resData.data.availableRoles) {
            setFamilyRole(resData.data.availableRoles[0])
          }
        })
        .catch(err => console.log(err))
    }
  }

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div
          style={{

            width: '100%',
            padding: '30px'
          }}
        >
          <p style={{
            color: '#2dc000',
            fontSize: '32px',
            fontFamily: 'UTM-APTIMABOLD'
          }}>
            {LANGUAGE === 'VI'
              ?
              'Thông tin'
              :
              'Information'
            }
          </p>
          <p style={{
            color: '#0172ff',
            fontSize: '42px',
            fontFamily: 'UTM-APTIMABOLD',
            marginTop: '-20px'
          }}>
            {LANGUAGE === 'VI'
              ?
              'ĐĂNG KÝ'
              :
              'REGISTER'
            }
          </p>
          <h3
            style={{
              color: '#333',
              fontFamily: 'UTM-APTIMA',
              fontSize: '22px'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Xin vui lòng nhập thông tin chính xác và đầy đủ của bạn'
              :
              'Please enter your correct and complete information'
            }

          </h3>
          <div
            style={{
              height: '60px',
            }}
          >
          </div>

          <h3 style={{ color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Họ và tên'
              :
              'Full name'
            }
          </h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Năm sinh'
              :
              'Year of Birth'
            }
          </h3>
          <input
            value={birthYear}
            onChange={(e) => setBirthYear(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Giới tính'
              :
              'Gender'
            }
          </h3>
          <select
            onChange={(e) => setGender(e.target.value)}
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '20px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            <option key='male' value='male' style={{ fontFamily: 'UTM-APTIMA' }}>
              {LANGUAGE === 'VI'
                ?
                'Nam'
                :
                'Male'
              }
            </option>
            <option key='female' value='female' style={{ fontFamily: 'UTM-APTIMA' }}>
              {LANGUAGE === 'VI'
                ?
                'Nữ'
                :
                'Female'
              }
            </option>
          </select>

          <h3 style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Số điện thoại'
              :
              'Phone number'
            }
          </h3>
          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="number"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />
          <p className='d-none' style={{fontSize: '18px', color: '#3d99ff', fontFamily: 'UTM-APTIMA', textAlign: 'justify' }}>* Nếu xảy ra trường hợp một gia đình chỉ có 1 SĐT nhưng lại có nhiều thành viên tham gia phân tích, bạn có thể thêm vào phía sau 1 hoặc 2 số nữa để tránh trùng lặp</p>

          <h3 style={{marginTop: '20px', color: '#9498ff', fontFamily: 'UTM-APTIMA'}}>
            {LANGUAGE === 'VI'
              ?
              '* Mã Cộng tác viên'
              :
              '* Saler code'
            }
          </h3>
          <input
            value={agencyAlias}
            onChange={(e) => setAgencyAlias(e.target.value)}
            type="text"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />

          <h3
            className='d-none'
            style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Địa chỉ'
              :
              'Address'
            }
          </h3>
          <select
            className='d-none'
            onChange={(e) => setAgencyAlias(e.target.value)}
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '20px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {
              addressArr.map((address) =>
                <option key={address.name} value={address.name} style={{ fontFamily: 'UTM-APTIMA' }}>
                  {address.alias}
                </option>
              )
            }

          </select>

          <h3 className='' style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Mã gia đình (nên là số điện thoại của mẹ)'
              :
              'Family code (should be mother\'s phone number)'
            }
          </h3>
          <input
            value={familyPhone}
            onChange={(e) => setFamilyPhone(e.target.value)}
            onBlur={() => {
              checkFamilyPhone()
            }}
            type="number"
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '22px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMA'
            }}
          />
          <p className='' style={{fontSize: '18px', color: '#3d99ff', fontFamily: 'UTM-APTIMA', textAlign: 'justify' }}>* Nếu bạn đang muốn làm cho cả gia đình, hãy điền vào mục mã gia đình, chúng tôi khuyên bạn nên lựa chọn số điện thoại của người mẹ cho dễ nhớ. Từ ngày 1/9/2024, báo cáo sẽ bổ sung bản đồ giao tiếp trong gia đình, là công cụ rất giá trị để cả nhà hiểu nhau, biết cách giao tiếp với nhau theo đúng nhu cầu tính cách của từng người. Dựa trên mã gia đình này, phần mềm sẽ kết nối các thành viên trong nhà lại với nhau trên bản đồ. <br/> Sau khi điền mã gia đình, bạn vui lòng chọn vai trò trong gia đình. Bản đồ giao tiếp này chỉ dành cho gia đình 2 thế hệ, tức là chỉ gồm 2 vợ chồng và các con. <br/> Nếu bạn muốn thực hiện một mình, có thể bỏ qua phần mã số gia đình và vai trò này.</p>

          <h3 className='' style={{marginTop: '20px', color: '#aaa', fontFamily: 'UTM-APTIMA' }}>
            {LANGUAGE === 'VI'
              ?
              'Chọn vai trò trong gia đình'
              :
              'Choose family role'
            }
          </h3>
          <select
            onChange={(e) => setFamilyRole(e.target.value)}
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '20px',
              fontWeight: 'bolder',
              color: '#6858ab',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            {familyRoles.length > 0 && familyRoles.map((familyRole) =>
              <option key={familyRole} value={familyRole} style={{ fontFamily: 'UTM-APTIMA' }}>
                {familyRole === 'FATHER' && 'Bố (Chồng)'}
                {familyRole === 'MOTHER' && 'Mẹ (Vợ)'}
                {familyRole === 'CHILD_ONE' && 'Con thứ nhất'}
                {familyRole === 'CHILD_TWO' && 'Con thứ hai'}
                {familyRole === 'CHILD_THREE' && 'Con thứ ba'}
                {familyRole === 'CHILD_FOUR' && 'Con thứ tư'}
                {familyRole === 'CHILD_FIVE' && 'Con thứ năm'}
              </option>
            )}
          </select>

          <div
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              fontFamily: 'UTM-APTIMA'
            }}
          >
            {errorMsg && <h3
              style={{
                color: 'red'
              }}
            >{errorMsg}
            </h3>}

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '5px',
              borderWidth: '0px'
            }}
            onClick={() => {
              setIsCorrect(false)
              axios.post(`${BACKEND_URL}/customer/check`, {
                phone: phone,
                agency_alias: agencyAlias,
              }).then(res => res.data)
                .then(resData => {
                  if (resData.error_code === 1) {
                    dispatch({
                      type: 'SET_STEP_ONE',
                      payload: {
                        name: name,
                        birthYear: birthYear,
                        phone: phone,
                        password: phone,
                        agencyAlias: agencyAlias,
                        familyPhone: familyPhone,
                        familyRole: familyRole,
                        gender: gender
                      }
                    })
                    history.push('/step-two2')
                  } else {
                    setErrorMsg(resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                  }
                })
            }}
          >
            <span
              style={{
                fontSize: '22px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TIẾP TỤC'
                :
                'CONTINUE'
              }
            </span>
          </button>


        </div>


      </div>

    </div>
  )
}

export {StepOne}
