import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const StepZero = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [language, setLanguage] = useState('male');
  const [isChild, setIsChild] = useState(0);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [currentDistricts, setCurrentDistricts] = useState([])
  const [isCorrect, setIsCorrect] = useState(true);
  const [hasModal, setHasModal] = useState(false);

  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >
          <h1 style={{
            color: '#2dc001',
            fontSize: '32px',
            textAlign: 'center',
            fontFamily: 'UTM-APTIMABOLD'
          }}>
            {LANGUAGE === 'VI'
              ?
              'ĐĂNG KÝ'
              :
              'REGISTER'
            }
          </h1>
          <h1 style={{
            color: '#0172ff',
            fontSize: '80px',
            textAlign: 'center',
            fontFamily: 'UTM-APTIMABOLD',
            marginTop: '-20px'
          }}>
            {LANGUAGE === 'VI'
              ?
              'ANBI'
              :
              'ANBI'
            }
          </h1>

          <p style={{marginTop: '30px', color: '#aaa', fontFamily: 'UTM-APTIMA', fontSize: '20px'}}>
            Chọn ngôn ngữ/Select language
          </p>
          <select
            onChange={(e) => {
              let language = e.target.value
              dispatch({
                type: 'SET_LANGUAGE',
                payload: {
                  language: language
                }
              })
            }}
            style={{
              width: '100%',
              height: '45px',
              border: '2px solid #ddd',
              borderRadius: '5px',
              paddingLeft: '15px',
              paddingRight: '15px',
              fontSize: '20px',
              fontWeight: 'bolder',
              color: '#6858ab',
              marginBottom: '40px',
              fontFamily: 'UTM-APTIMABOLD'
            }}
          >
            <option value="VI" style={{ fontFamily: 'UTM-APTIMA' }}>
              Tiếng Việt
            </option>
            <option value="EN" style={{ fontFamily: 'UTM-APTIMA' }}>
              English
            </option>
          </select>

          <div
            style={{
              backgroundColor: '#def2fd',
              padding: '20px',
              borderRadius: '10px',
              marginBottom: '50px'
            }}
          >
            <img
              style={{ width: '100%', borderRadius: '10px', marginBottom: '10px' }}
              src={toAbsoluteUrl('/media/logos/anbi.jpg')} alt='anbi'
            />
            <p style={{ color: '#020203', fontFamily: 'UTM-APTIMA', fontSize: '20px', lineHeight: '1.3' }} >1. Mỗi khuôn mặt tham gia phân tích chỉ được
              <span style={{ color: '#000', fontFamily: 'UTM-APTIMAITALIC', fontWeight: 'bold' }}> trợ giá 01 lần </span>
              (chi phí phân tích lại: 100 USD/lần).
            </p>
            <p style={{ color: '#020203', fontFamily: 'UTM-APTIMA', fontSize: '20px', lineHeight: '1.3' }} >2. Học sinh nhỏ hơn 9 tuổi cần được
              <span style={{ color: '#000', fontFamily: 'UTM-APTIMAITALIC' }}> phụ huynh hoặc giáo viên </span>
              giúp hoàn thiện đăng ký
            </p>
            <p style={{ color: '#020203', fontFamily: 'UTM-APTIMA', fontSize: '20px', lineHeight: '1.3' }} >3. Cơ chế
              <span style={{ color: '#000', fontFamily: 'UTM-APTIMAITALIC' }}> hoạt động </span>
              của công nghệ Anbi...
              <span
                onClick={() => setHasModal(true)}
                style={{ color: '#ff0000', textDecoration: 'underline', fontFamily: 'UTM-APTIMAITALIC', cursor: 'pointer' }}>Xem thêm</span>
            </p>
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(89, 89, 89, 0.5)',
            }}
          >
          </div>

          <div
            style={{
              display: hasModal ? 'block' : 'none',
              position: 'fixed',
              width: '380px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              textAlign: 'center',
              borderRadius: '10px',
              fontFamily: 'UTM-APTIMABOLD',
              height: '100%',
              overflow: 'scroll'
            }}
          >
            <img src={toAbsoluteUrl('/media/logos/co-che-anbi.jpg')} style={{ width: '340px', height: 'auto'}} alt='' />

            <div
              onClick={() => setHasModal(false)}
              style={{
                width: '60%',
                border: '2px solid #ddd',
                borderRadius: '10px',
                fontSize: '22px',
                fontWeight: 'bolder',
                backgroundColor: '#a6a6a6',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px',
                color: 'white',
                marginTop: '20px',
                marginBottom: '20px',
                fontFamily: 'UTM-APTIMABOLD',
                margin: 'auto'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'ĐÓNG'
                :
                'CLOSE'
              }
            </div>

            <div style={{ height: '20px' }}></div>

          </div>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              fontFamily: 'UTM-APTIMA'
            }}
            onClick={() => {
              history.push('/step-one')
            }}
          >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'BẮT ĐẦU'
                :
                'START'
              }

            </span>
          </button>
        </div>


      </div>

    </div>
  )
}

export {StepZero}
