import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {CustomerReport} from "../pages/CustomerReport";
import {StepZero} from "../pages/StepZero";
import {StepZero1} from "../pages/StepZero1";
import {StepOne} from "../pages/StepOne";
import {StepAdmin} from "../pages/StepAdmin";
import {StepTwo} from "../pages/StepTwo";
import {StepTwo1} from "../pages/StepTwo1";
import {StepTwo2} from "../pages/StepTwo2";
import {StepTwo3} from "../pages/StepTwo3";
import {StepThree} from "../pages/StepThree";
import {StepFour} from "../pages/StepFour";
import {StepFive} from "../pages/StepFive";
import {StepSix} from "../pages/StepSix";
import {StepSeven} from "../pages/StepSeven";
import {StepEight} from "../pages/StepEight";
import {Result} from "../pages/Result";
import {Tutorial} from "../pages/Tutorial";
import {ReportTutorial} from "../pages/ReportTutorial";
import {Intro} from "../pages/Intro";
import {Intro1} from "../pages/Intro1";
import {Intro2} from "../pages/Intro2";
import {Donate} from "../pages/Donate";
import {NoteScan} from "../pages/NoteScan";
import {NoteLogin} from "../pages/NoteLogin";
import {Maintenance} from "../pages/Maintenance";
import {CustomerStudy} from "../pages/CustomerStudy";
import {CustomerStudyDes} from "../pages/CustomerStudyDes";
import {CustomerIntro} from "../pages/CustomerIntro";
import {CustomerInfo} from "../pages/CustomerInfo";
import {CustomerInfoChangePassword} from "../pages/CustomerInfoChangePassword";
import {CustomerReportVideo} from "../pages/CustomerReportVideo";
import {CustomerReportImage} from "../pages/CustomerReportImage";

const Routes: FC = () => {
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const userInfo: any = useSelector<RootState>(({userInfo}) => userInfo, shallowEqual)

  let hasInfo = false
  if (userInfo.name && userInfo.phone && userInfo.agency_alias) {
    hasInfo = true
  }

  return (
    <Switch>
      {/*/!*test*!/*/}
      {/*<Route path='/step-zero' component={StepZero} />*/}
      {/*<Route path='/note-scan' component={NoteScan} />*/}
      {/*<Route path='/note-login' component={NoteLogin} />*/}
      {/*<Route path='/tutorial' component={Tutorial} />*/}
      {/*<Route path='/intro' component={Intro} />*/}
      {/*<Route path='/intro1' component={Intro1} />*/}
      {/*<Route path='/intro2' component={Intro2} />*/}
      {/*<Route path='/donate' component={Donate} />*/}
      {/*<Route path='/step-zero1' component={StepZero1} />*/}
      {/*<Route path='/step-one' component={StepOne} />*/}
      {/*<Route path='/step-two' component={StepTwo} />*/}
      {/*<Route path='/step-two1' component={StepTwo1} />*/}
      {/*<Route path='/step-two2' component={StepTwo2} />*/}
      {/*<Route path='/step-two3' component={StepTwo3} />*/}
      {/*<Route path='/step-three' component={StepThree} />*/}
      {/*<Route path='/step-four' component={StepFour} />*/}
      {/*<Route path='/step-five' component={StepFive} />*/}
      {/*<Route path='/step-six' component={StepSix} />*/}
      {/*<Route path='/step-seven' component={StepSeven} />*/}
      {/*<Route path='/step-eight' component={StepEight} />*/}
      {/*<Route path='/result' component={Result} />*/}
      {/*/!*test*!/*/}


      <Route path='/report-tutorial' component={ReportTutorial} />
      <Route path='/admin' component={StepAdmin} />
      {hasInfo ? (
        <>
          <Route path='/tutorial' component={Tutorial} />
          <Route path='/intro' component={Intro} />
          <Route path='/intro2' component={Intro2} />
          <Route path='/intro1' component={Intro1} />
          <Route path='/step-zero' component={StepZero} />
          <Route path='/step-zero1' component={StepZero1} />
          <Route path='/step-one' component={StepOne} />
          <Route path='/step-two' component={StepTwo} />
          <Route path='/step-two1' component={StepTwo1} />
          <Route path='/step-two2' component={StepTwo2} />
          <Route path='/step-two3' component={StepTwo3} />
          <Route path='/step-three' component={StepThree} />
          <Route path='/step-four' component={StepFour} />
          <Route path='/step-five' component={StepFive} />
          <Route path='/step-six' component={StepSix} />
          <Route path='/step-seven' component={StepSeven} />
          <Route path='/step-eight' component={StepEight} />
          <Route path='/result' component={Result} />
          <Route path='/note-scan' component={NoteScan} />
          <Route path='/donate' component={Donate} />
          <Route path='/note-login' component={NoteLogin} />
          <Redirect to='/intro' />
        </>
      ) : (
        <>
          <Route path='/tutorial' component={Tutorial} />
          <Route path='/intro' component={Intro} />
          <Route path='/intro2' component={Intro2} />
          <Route path='/intro1' component={Intro1} />
          <Route path='/step-zero' component={StepZero} />
          <Route path='/step-zero1' component={StepZero1} />
          <Route path='/step-one' component={StepOne} />
          <Route path='/donate' component={Donate} />
          <Route path='/maintenance' component={Maintenance} />
          <Redirect to='/intro' />
        </>
      )}
    </Switch>

  )
}

export {Routes}
