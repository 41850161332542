/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";
import {toast} from "react-toastify";
import {SET_STEP_ONE} from "../../setup/redux/userReducer";

const Maintenance = () => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const history = useHistory()

  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [agencyAlias, setAgencyAlias] = useState('');
  const [isCorrect, setIsCorrect] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (name && phone && agencyAlias) {
      setIsCorrect(true)
    } else {
      setIsCorrect(false)
    }
  }, [name, phone, agencyAlias])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          height: '100%',
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >
        <div
          style={{

            width: '100%',
            height: '80px'
          }}
        >

          <div
            style={{
              width: '15%',
              height: '80px',
              display: 'inline-block',
              position: 'relative',
              float: 'left'
            }}
          >
            <img style={{
              display: 'none',
              width: '30px',
              height: '30px',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }} src={toAbsoluteUrl('/media/logos/left.png')} alt=""/>
          </div>

          <div
            style={{

              width: '70%',
              height: '80px',
              display: 'inline-block',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              float: 'left'
            }}
          >
            <h1
              style={{
                marginTop: '30px'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'Thông báo'
                :
                'Notification'
              }
            </h1>
          </div>

          <div
            style={{

              width: '15%',
              height: '80px',
              display: 'inline-block',
              position: 'relative',
              float: 'left'
            }}
          >
            <img style={{
              display: 'none',
              width: '30px',
              height: '30px',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }} src={toAbsoluteUrl('/media/logos/right.png')} alt=""/>
          </div>

        </div>

        <div
          style={{
            height: '60px',
          }}
        >
        </div>

        <div
          style={{
            width: '100%',
            padding: '30px',
            textAlign: 'center'
          }}
        >
          <h3
            style={{
              color: '#ff6c00'
            }}
          >
            {LANGUAGE === 'VI'
              ?
              'Hệ thống đang cập nhật phiên bản mới, vui lòng quay lại sau!'
              :
              'The system is updating to a new version, please come back later!'
            }

          </h3>

        </div>


      </div>

    </div>
  )
}

export {Maintenance}
