import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const Donate = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth

  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [language, setLanguage] = useState('male');
  const [isChild, setIsChild] = useState(0);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [currentDistricts, setCurrentDistricts] = useState([])
  const [isCorrect, setIsCorrect] = useState(true);
  const [hasModal, setHasModal] = useState(true);

  const userInfo = useSelector(state => state.userInfo)
  console.log({userInfo})
  const LANGUAGE = userInfo.language

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div style={{ backgroundColor: '#333', height: '100%'}}>
      <div
        style={{
          width: WIDTH,
          margin: 'auto',
          backgroundColor: '#fff',
        }}
      >

        <div>
          {/*<iframe*/}
          {/*  style={{ width: '100%', height: '100vh' }}*/}
          {/*  src="https://www.robotanan.com/webviewunghoanbi"*/}
          {/*  frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"*/}
          {/*/>*/}
        </div>


        <img src={toAbsoluteUrl('/media/logos/donate-1.jpg')} style={{ width: WIDTH }} alt='intro' />

        <div
          style={{
            display: 'none',
            padding: '30px',
            textAlign: 'center'
          }}
        >
          <a href="https://mau.robotanan.com/" target='_blank'>
            <button
              type="button"
              style={{
                padding: '15px',
                backgroundColor: '#30a3fc',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bolder',
                  color: '#fff',
                  letterSpacing: 2.0
                }}
              >
                BÁO CÁO MẪU
              </span>
            </button>
          </a>
        </div>

        <img src={toAbsoluteUrl('/media/logos/donate-2.jpg')} style={{ width: WIDTH, display: 'none' }} alt='intro' />

        <div
          style={{
            backgroundColor: '#f2f2f2',
            borderRadius: '15px',
            padding: '20px'
          }}
        >
          <div
            style={{
              backgroundColor: '#fff',
              border: '2px solid #333',
              borderRadius: '20px',
              margin: '20px',
              padding: '10px',
              textAlign: 'center'
            }}
          >
                            <span
                              style={{
                                color: '#000',
                                fontSize: '18px',
                                marginLeft: '10px',
                                fontFamily: 'UTM-APTIMABOLD',
                                fontWeight: 'bold'
                              }}
                            >
                              THÔNG TIN<br/>CHUYỂN KHOẢN ỦNG HỘ
                          </span>
          </div>

          {/*chính phụ chặng*/}
          <div
            style={{
              // backgroundColor: '#000'
            }}
          >
            {/*  <div*/}
            {/*    style={{*/}
            {/*      width: '25%',*/}
            {/*      // backgroundColor: 'red',*/}
            {/*      display: 'inline-block'*/}
            {/*    }}*/}
            {/*  >*/}
            {/*<span*/}
            {/*  style={{*/}
            {/*    color: '#333',*/}
            {/*    fontSize: '26px',*/}
            {/*    lineHeight: 1.2,*/}
            {/*    fontFamily: 'UTM-APTIMA',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  SƠ LƯỢC*/}
            {/*</span>*/}
            {/*  </div>*/}
            <div
              style={{
                width: '100%',
                // backgroundColor: 'green',
                display: 'inline-block'
              }}
            >

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Ngân hàng: ' : 'Bank: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                Quân đội MBBank
                              </span>
              </div>

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Số tài khoản: ' : 'Bank number: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                1988288288
                              </span>
                <button className='btn btn-success btn-sm ms-4'
                        style={{ marginTop: '-10px' }}
                        onClick={() => navigator.clipboard.writeText('1988288288')}
                >
                  Copy
                </button>
              </div>

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Tên tài khoản: ' : 'Owner: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                Trần Ngọc Thiện
                                <br/>
                                (sáng lập dự án trí tuệ nhân tạo Anan và Anbi)
                              </span>
              </div>


              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {'Số tiền ủng hộ: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                100.000 đ/người
                              </span>
              </div>

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '20px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Nội dung: ' : 'Content: '}
                              </span>
                <span
                  style={{
                    color: '#fd1a1c',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMABOLD'
                  }}
                >
                                  {'UNG HO - ' + userInfo.agency_alias + ' - ' + userInfo.phone}
                                </span>
                <button className='btn btn-success btn-sm ms-4'
                        style={{ marginTop: '-10px' }}
                        onClick={() => navigator.clipboard.writeText('UNG HO - ' + userInfo.agency_alias + ' - ' + userInfo.phone)}
                >
                  Copy
                </button>
              </div>

              <div
                style={{
                  borderBottom: '1px dashed #b0b0b0',
                  margin: '5px',
                  padding: '10px 10px 10px 0px'
                }}
              >
                <p
                  style={{
                    color: '#000',
                    fontSize: '20px',
                    fontFamily: 'UTM-APTIMA',
                    textAlign: 'center'
                  }}
                >
                  Chúng tôi chân thành cảm ơn và trân trọng sự ủng hộ của gia đình bạn!
                </p>
              </div>

              <div
                style={{
                  margin: '5px',
                  padding: '10px 10px 10px 0px',
                  textAlign: 'center'
                }}
              >
                              <span
                                style={{
                                  color: '#333',
                                  fontSize: '22px',
                                  fontFamily: 'UTM-APTIMA'
                                }}
                              >
                                {LANGUAGE === 'VI' ? 'Mã QR: ' : 'QR code: '}
                              </span>
                <br/>
                <div className='text-center'>
                  <img style={{ width: '90%', borderRadius: '5px', marginTop: '10px' }} src={toAbsoluteUrl('/media/logos/qr-tai-tro.jpg')} alt=""/>
                </div>
              </div>


            </div>


          </div>
        </div>


        <div
          style={{
            width: '100%',
            padding: '30px'
          }}
        >

          <a href="https://www.robotanan.com/quatanganbi" target='_blank'>
            <button
              disabled={ isCorrect ? '' : 'disabled' }
              type="button"
              style={{
                display: 'none',
                width: '100%',
                padding: '15px',
                backgroundColor: isCorrect ? '#999' : '#999',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                borderWidth: '0px',
                fontFamily: 'UTM-APTIMA'
              }}
            >
              <span
                style={{
                  fontSize: '20px',
                  fontWeight: 'bolder',
                  color: '#fff',
                  letterSpacing: 2.0
                }}
              >
                CÔNG NGHỆ ANBI
              </span>
            </button>
          </a>

          <button
            disabled={ isCorrect ? '' : 'disabled' }
            type="button"
            style={{
              width: '100%',
              padding: '15px',
              backgroundColor: isCorrect ? '#30a3fc' : '#a1e6b9',
              justifyContent: 'center',
              textAlign: 'center',
              alignItems: 'center',
              borderRadius: '10px',
              borderWidth: '0px',
              fontFamily: 'UTM-APTIMA',
              marginTop: '25px'
            }}
            onClick={() => {
              axios.post(`${BACKEND_URL}/confirm-donate`, {
                phone: userInfo.phone,
              }).then(res => res.data)
                .then(resData => console.log({resData}))
              history.push('/note-login')
            }}
          >
            <span
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#fff',
                letterSpacing: 1.2
              }}
            >
              XÁC NHẬN ĐÃ HOÀN THÀNH
            </span>
            <br/>
            <span
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#fff',
                letterSpacing: 1.2
              }}
            >
              CHUYỂN KHOẢN ỦNG HỘ 100K
            </span>
          </button>
        </div>


      </div>

    </div>
  )
}

export {Donate}
