import React, {FC, useEffect, useState} from 'react'
import * as auth from "../modules/auth/redux/AuthRedux";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link, useHistory} from "react-router-dom";
import {RootState} from "../../setup";
import axios from "axios";
import {BACKEND_URL} from "../modules/auth/redux/AuthCRUD";

const NoteLogin = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const HEIGHT = window.innerWidth > 400 ? '400px' : window.innerHeight

  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');
  const [birthYear, setBirthYear] = useState('');
  const [language, setLanguage] = useState('male');
  const [isChild, setIsChild] = useState(0);
  const [provinceId, setProvinceId] = useState(1);
  const [districtId, setDistrictId] = useState(1);
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [currentDistricts, setCurrentDistricts] = useState([])
  const [isCorrect, setIsCorrect] = useState(true);
  const [hasModal, setHasModal] = useState(false);

  const userInfo = useSelector(state => state.userInfo)
  const LANGUAGE = userInfo.language

  return (
    <div style={{ backgroundColor: '#fff', height:  '100%' }}>
      <div
        style={{
          width: WIDTH,
          margin: 'auto',
          backgroundColor: '#333',
        }}
      >
        <div>
          <img src={toAbsoluteUrl('/media/logos/note-login.jpg')} style={{ width: WIDTH, marginBottom: '20px' }} alt='intro' />
          <div
            style={{
              width: WIDTH,
              padding: '30px',
              position: 'absolute',
              marginTop: '-360px'
            }}
          >
            <a
              href='https://user.robotanan.com/auth/login'
              target='_blank'
              style={{
                width: '100%',
                padding: '15px',
                backgroundColor: '#30a3fc',
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
                borderRadius: '15px',
                borderWidth: '0px',
                display: 'block'
              }}
            >
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'bolder',
                color: '#fff',
                letterSpacing: 2.0,
                fontFamily: 'UTM-APTIMABOLD'
              }}
            >
              {LANGUAGE === 'VI'
                ?
                'TÔI ĐÃ HIỂU'
                :
                'I UNDERSTAND'
              }
            </span>
            </a>
          </div>
        </div>


      </div>

    </div>
  )
}

export {NoteLogin}
